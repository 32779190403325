.icon-gu-Troop.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/troop-center.png') round;
  }
  .icon-gu-Trooper.Recruit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/trooper-recruit.png') round;
  }
  .icon-gu-Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/trooper.png') round;
  }
  .icon-gu-Hvy\..Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-trooper.png') round;
  }
  .icon-gu-Repeater.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/repeater-trooper.png') round;
  }
  .icon-gu-Grenade.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/grenade-trooper.png') round;
  }
  .icon-gu-Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/anti-air-trooper.png') round;
  }
  
  .icon-gu-Hvy\..Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-anti-air-trooper.png') round;
  }
  .icon-gu-Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/mounted-trooper.png') round;
  }
  .icon-gu-Hvy\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-mounted-trooper.png') round;
  }
  .icon-gu-Adv\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-mounted-trooper.png') round;
  }
  
  .icon-gu-Macro.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/macro-binoculars.png') round;
  }
  
  .icon-gu-Lighter.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/lighter-armor.png') round;
  }
  .icon-gu-Plasma.Detonators {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/plasma-detonators.png') round;
  }
  .icon-gu-Dexterity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/dexterity.png') round;
  }
  .icon-gu-Droid.Assistants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/droid-assistants.png') round;
  }
  
  .icon-gu-Explosive.Yields.Increase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/explosive-yields-increased.png') round;
  }
  
  .icon-gu-Portable.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/portable-scanners.png') round;
  }
  
  .icon-gu-Integrated.Rangefinder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/integrated-rangefinder.png') round;
  }
  
  .icon-gu-Jedi.Temple {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/jedi-temple.png') round;
  }
  
  .icon-gu-Jedi.Padawan {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/jedi-padawan.png') round;
  }
  .icon-gu-Jedi.Knight {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/jedi-knight.png') round;
  }
  .icon-gu-Jedi.Master {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/jedi-master.png') round;
  }
  .icon-gu-Force.Stamina {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-stamina.png') round;
  }
  .icon-gu-Force.Strong {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-strong.png') round;
  }
  .icon-gu-Force.Agility {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-agility.png') round;
  }
  .icon-gu-Faith.in.the.Force {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/faith-in-the-force.png') round;
  }
  .icon-gu-Force.Concentration {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-concentration.png') round;
  }
  .icon-gu-Force.Meditation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-meditation.png') round;
  }
  .icon-gu-Force.Perception {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-perception.png') round;
  }
  
  .icon-gu-Jedi.Mind.Trick {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/jedi-mind-trick.png') round;
  }
  
  .icon-gu-Force.Purge {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-purge.png') round;
  }
  
  .icon-gu-Airbase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/airbase.png') round;
  }
  .icon-gu-Air.Transport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/air-transport.png') round;
  }
  .icon-gu-Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/bomber.png') round;
  }
  .icon-gu-Enh.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/enh-bomber.png') round;
  }
  .icon-gu-Adv.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-bomber.png') round;
  }
  .icon-gu-Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/fighter.png') round;
  }
  .icon-gu-Fast.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/fast-fighter.png') round;
  }
  .icon-gu-Adv.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-fighter.png') round;
  }
  .icon-gu-Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/flight-school.png') round;
  }
  .icon-gu-Advanced.Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/advanced-flight-school.png') round;
  }
  .icon-gu-Enlarged.Bomb.Hold {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/enlarged-bomb-hold.png') round;
  }
  .icon-gu-Attacker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/attacker.png') round;
  }
  .icon-gu-Efficient.Manufacturing {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/efficient-manufacturing.png') round;
  }
  .icon-gu-Shield.Modifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/shield-modifications.png') round;
  }
  .icon-gu-Armored.Platework {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/armored-platework.png') round;
  }
  
  .icon-gu-Mech.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/mech-factory.png') round;
  }
  
  .icon-gu-Scout {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/scout.png') round;
  }
  
  .icon-gu-Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/strike-mech.png') round;
  }
  .icon-gu-Hvy.Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-strike-mech.png') round;
  }
  .icon-gu-Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/mech-destroyer.png') round;
  }
  .icon-gu-Hvy.Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-mech-destroyer.png') round;
  }
  .icon-gu-Upgraded.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/upgraded-generator.png') round;
  }
  
  .icon-gu-Adv.Generator{
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-generator.png') round;
  }
  .icon-gu-Transport.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/transport-mech.png') round;
  }
  .icon-gu-Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/assault-mech.png') round;
  }
  .icon-gu-Force.Influence {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/force-influence.png') round;
  }
  .icon-gu-Hvy.Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-assault-mech.png') round;
  }
  
  .icon-gu-Shipyard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/shipyard.png') round;
  }
  .icon-gu-Utility.Trawler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/utility-trawler.png') round;
  }
  .icon-gu-Aqua.Harvester {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/aqua-harvester.png') round;
  }
  .icon-gu-Sensor.Buoy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/sensor-buoy.png') round;
  }
  
  .icon-gu-Lt.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/lt-frigate.png') round;
  }
  .icon-gu-Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/frigate.png') round;
  }
  .icon-gu-Adv.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-frigate.png') round;
  }
  .icon-gu-Transport.Ship {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/transport-ship.png') round;
  }
  
  .icon-gu-Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/destroyer.png') round;
  }
  .icon-gu-Hvy.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-destroyer.png') round;
  }
  .icon-gu-Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/anti-air-destroyer.png') round;
  }
  .icon-gu-Hvy.Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-anti-air-destroyer.png') round;
  }
  
  .icon-gu-Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/monitor.png') round;
  }
  .icon-gu-Hvy.Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-monitor.png') round;
  }
  .icon-gu-Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/cruiser.png') round;
  }
  .icon-gu-Adv.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-cruiser.png') round;
  }
  
  .icon-gu-Hvy.Weapons.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-weapons-factory.png') round;
  }
  
  .icon-gu-Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/pummel.png') round;
  }
  
  .icon-gu-Hvy.Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-pummel.png') round;
  }
  
  .icon-gu-Anti-Air.Retrofit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/anti-air-retrofit.png') round;
  }
  
  .icon-gu-Reinforced.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/reinforced-frame.png') round;
  }
  
  .icon-gu-Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/artillery.png') round;
  }
  
  .icon-gu-Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/anti-air-mobile.png') round;
  }
  
  .icon-gu-Hvy.Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-anti-air-mobile.png') round;
  }
  
  .icon-gu-Strengthened.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/strengthened-frame.png') round;
  }
  
  .icon-gu-Air.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/air-cruiser.png') round;
  }
  
  .icon-gu-Cannon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/cannon.png') round;
  }
  
  .icon-gu-Bothan.Spy.Net {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/bothan-spy-net.png') round;
  }
  
  .icon-gu-Attack.Programming {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/attack-programming.png') round;
  }
  
  .icon-gu-Automated.Processes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/automated-processes.png') round;
  }
  .icon-gu-Presidium {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/presidium.png') round;
  }
  
  .icon-gu-Bounty.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/bounty-hunter.png') round;
  }
  
  .icon-gu-Fortress {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/fortress.png') round;
  }
  
  .icon-gu-Worker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/worker.png') round;
  }
  
  .icon-gu-Medic {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/medic.png') round;
  }
  
  .icon-gu-Basic.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/basic-training.png') round;
  }
  
  .icon-gu-Sensor.Beacon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/sensor-beacon.png') round;
  }
  
  .icon-gu-Sensor.Array {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/sensor-array.png') round;
  }
  
  .icon-gu-Upgraded.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/upgraded-motivator.png') round;
  }
  
  .icon-gu-Optimized.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/optimized-motivator.png') round;
  }
  
  .icon-gu-Bacta.Tanks {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/bacta-tanks.png') round;
  }
  
  .icon-gu-Command.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/command-center.png') round;
  }
  
  .icon-gu-War.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/war-center.png') round;
  }
  .icon-gu-Basic.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/basic-armor.png') round;
  }
  .icon-gu-Light.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/light-armor.png') round;
  }
  .icon-gu-Hvy.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-armor.png') round;
  }
  .icon-gu-Elevation.Tracking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/elevation-tracking.png') round;
  }
  .icon-gu-External.Sensor.Pod {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/external-sensor-pod.png') round;
  }
  .icon-gu-Targeting.Sensor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/targeting-sensor.png') round;
  }
  .icon-gu-Light.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/light-plating.png') round;
  }
  .icon-gu-Medium.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/medium-plating.png') round;
  }
  .icon-gu-Hvy.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-plating.png') round;
  }
  .icon-gu-Primary.Focusing.Coils {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/primary-focusing-coils.png') round;
  }
  .icon-gu-Cooling.Sleeves {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/cooling-sleeves.png') round;
  }
  
  .icon-gu-Advanced.Power.Pack {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/advanced-power-pack.png') round;
  }
  .icon-gu-Redesigned.Specifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/redesigned-specifications.png') round;
  }
  
  .icon-gu-Grenadier.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/grenadier-training.png') round;
  }
  .icon-gu-Advanced.Scanning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/advanced-scanning.png') round;
  }
  
  .icon-gu-Spaceport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/spaceport.png') round;
  }
  
  .icon-gu-Cargo.Hovercraft {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/cargo-hovercraft.png') round;
  }
  .icon-gu-Hutt.Endorsement {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hutt-endorsement.png') round;
  }
  
  .icon-gu-Galactic.Banking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/galactic-banking.png') round;
  }
  
  .icon-gu-Galactic.Trade.Comm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/galactic-trade-commission.png') round;
  }
  .icon-gu-Holonet.Tranceiver {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/holonet-tranceiver.png') round;
  }
  
  
  .icon-gu-Cargo.Freighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/cargo-freighter.png') round;
  }
  
  .icon-gu-Research.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/research-center.png') round;
  }
  
  .icon-gu-Durasteel.Suppt.Beams {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/durasteel-support-beams.png') round;
  }
  
  .icon-gu-Permacite.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/permacite-plating.png') round;
  }
  
  .icon-gu-Ion.Accu.Accelerator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/ion-accu-accelerator.png') round;
  }
  
  .icon-gu-Rotation.Bearings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/rotation-bearings.png') round;
  }
  
  .icon-gu-Hvy.Weapons.Engineers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-weapons-engineers.png') round;
  }
  
  .icon-gu-Power.Calibrator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/power-calibrator.png') round;
  }
  
  .icon-gu-Tracking.and.Target.Comptr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/tracking-and-target-computers.png') round;
  }
    
  .icon-gu-AA.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/aa-battery.png') round;
  }
  
  .icon-gu-Homing.Sensors {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/homing-sensors.png') round;
  }
  
  .icon-gu-Carbon.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/carbon-proc-ctr.png') round;
  }
  
  .icon-gu-Handheld.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/handheld-carbon-extr.png') round;
  }
  
  .icon-gu-Enhanced.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/enhanced-carbon-extr.png') round;
  }
  
  .icon-gu-Hvy.Duty.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-duty-carbon-extr.png') round;
  }
  
  .icon-gu-Food.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/food-proc-ctr.png') round;
  }
  
  .icon-gu-Irrigation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/irrigation.png') round;
  }
  .icon-gu-Farm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1631706603/gu/farm.png') round;
  }
  
  .icon-gu-Harvesting.Program {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/harvesting-program.png') round;
  }
  
  
  .icon-gu-Adv.Harvest.Prgm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-harvest-program.png') round;
  }
  
  .icon-gu-Animal.Nursery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/animal-nursery.png') round;
  }
  .icon-gu-Stimulants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/stimulants.png') round;
  }
  
  .icon-gu-Genetics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/genetics.png') round;
  }
  
  .icon-gu-Cloning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/cloning.png') round;
  }
  
  .icon-gu-Nova.Crystal.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/nova-proc-ctr.png') round;
  }
  .icon-nova-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/beamdrill-mining-nova.png') round;
  }
  
  .icon-nova-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-duty-beamdrill-nova.png') round;
  }
  
  .icon-gu-Ore.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/ore-proc-ctr.png') round;
  }
  
  .icon-ore-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/beamdrill-mining-ore.png') round;
  }
  
  .icon-ore-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-duty-beamdrill-ore.png') round;
  }
  .icon-gu-Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/prefab-shelter.png') round;
  }
  .icon-gu-Gate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/gate.png') round;
  }
  
  .icon-gu-Light.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/light-wall.png') round;
  }
  .icon-gu-Medium.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/medium-wall.png') round;
  }
  .icon-gu-Shield.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/shield-wall.png') round;
  }
  
  .icon-gu-Hvy.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-wall.png') round;
  }
  .icon-gu-Monument {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/monument.png') round;
  }
  .icon-gu-Shield.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/shield-generator.png') round;
  }
  
  .icon-gu-Strengthened.Superstructure {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/strengthened-superstructure.png') round;
  }
  .icon-gu-Supercondctg.Shields {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/superconducting-shields.png') round;
  }
  .icon-gu-Light.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/light-turret.png') round;
  }
  
  .icon-gu-Medium.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/medium-turret.png') round;
  }
  
  .icon-gu-Advanced.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/advanced-turret.png') round;
  }
  .icon-gu-Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/anti-air-turret.png') round;
  }
  
  .icon-gu-Adv.Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-anti-air-turret.png') round;
  }
  
  .icon-gu-Power.Core {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/power-core.png') round;
  }
  .icon-gu-Power.Droid {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/power-droid.png') round;
  }
  .icon-gu-Strengthened.Assembly {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/strengthened-assembly.png') round;
  }
  
  .icon-gu-Efficient.Buildings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/efficient-buildings.png') round;
  }
  
  .icon-gu-Power.Core.Shielding {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/power-core-shielding.png') round;
  }
  .icon-gu-Mechanics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/mechanics.png') round;
  }
  .icon-gu-Advanced.Propulsion {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/advanced-propulsion.png') round;
  }
  .icon-gu-Redoubled.Efforts {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/redoubled-efforts.png') round;
  }
  
  .icon-gu-Bunkerbusters {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/bunkerbusters.png') round;
  }
  
  .icon-gu-Sensor.Cloak{
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/sensor-cloak.png') round;
  }
  
  .icon-gu-Ski.Speeder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/ski-speeder.png') round;
  }
  
  .icon-gu-Hvy.Ski.Speeder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-ski-speeder.png') round;
  }
  
  .icon-gu-Resilence {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/resilience.png') round;
  }
  
  .icon-gu-Supply.lines {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/supply-lines.png') round;
  }
  
  .icon-gu-Adv.Redesign {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round,  url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-redesign.png') round;
  }
  .icon-gu-Technicians {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/technicians.png') round;
  }

  .icon-gu-FarSeeIn.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/farseein-binoculars.png') round;
  }
  .icon-gu-Gungan.Creature.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/gungan-creature-armor.png') round;
  }
  .icon-gu-Hvy.Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-artillery.png') round;
  }
  .icon-gu-Creature.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/creature-training.png') round;
  }
  .icon-gu-Faster.Growth.Chambers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/faster-growth-chambers.png') round;
  }
  .icon-gu-Potent.Plasma {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/potent-plasma.png') round;
  }
  .icon-gu-Fambaa.Shield.Gen {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/fambaa-shield-generator.png') round;
  }
  .icon-gu-Hvy.Fambaa.Shield.Gen {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/hvy-fambaa-shield-generator.png') round;
  }
  .icon-gu-Interceptor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/interceptor.png') round;
  }
  .icon-gu-Advanced.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/adv-generator.png') round;
  }
  .icon-gu-Sentry.Post {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630744839/gu/sentry-post.png') round;
  }

  .icon-gu-Mine {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/gu/mine.png') round;
  }
  .icon-gu-Anti-Air.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/gu/anti-air-battery.png') round;
  }

  .icon-gu-Underwater.Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1631706684/gu/underwater-prefab-shelter.png') round;
  }