// first order

.icon-fo-Troop.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/troop-center.png') round;
  }
  .icon-fo-Trooper.Recruit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/trooper-recruit.png') round;
  }
  .icon-fo-Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/trooper.png') round;
  }
  .icon-fo-Hvy\..Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-trooper.png') round;
  }
  .icon-fo-Repeater.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/repeater-trooper.png') round;
  }
  .icon-fo-Grenade.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/grenade-trooper.png') round;
  }
  .icon-fo-Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/anti-air-trooper.png') round;
  }
  .icon-fo-Hvy\..Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-anti-air-trooper.png') round;
  }
  .icon-fo-Flametrooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/flametrooper.png') round;
  }
  .icon-fo-Hvy\..Flametrooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-flametrooper.png') round;
  }
  .icon-fo-Adv\..Flametrooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-flametrooper.png') round;
  }
  .icon-fo-Macro.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/macro-binoculars.png') round;
  }
  .icon-fo-Lighter.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/lighter-armor.png') round;
  }
  .icon-fo-Plasma.Detonators {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/plasma-detonators.png') round;
  }
  .icon-fo-Dexterity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/dexterity.png') round;
  }
  .icon-fo-Droid.Assistants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/droid-assistants.png') round;
  }
  .icon-fo-Explosive.Yields.Increase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/explosive-yields-increased.png') round;
  }
  .icon-fo-Portable.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/portable-scanners.png') round;
  }
  .icon-fo-Integrated.Rangefinder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/integrated-rangefinder.png') round;
  }
  .icon-fo-Ren.Temple {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/sith-temple.png') round;
  }
  .icon-fo-Ren.Initiate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/ren-initiate.png') round;
  }
  .icon-fo-Ren.Knight {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/ren-knight.png') round;
  }
  .icon-fo-Ren.Master {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/ren-master.png') round;
  }
  .icon-fo-Force.Stamina {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-stamina.png') round;
  }
  .icon-fo-Force.Influence {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-influence.png') round;
  }
  .icon-fo-Force.Strong {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-strong.png') round;
  }
  .icon-fo-Force.Agility {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-agility.png') round;
  }
  .icon-fo-Faith.in.the.Force {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/faith-in-the-force.png') round;
  }
  .icon-fo-Force.Concentration {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-concentration.png') round;
  }
  .icon-fo-Force.Meditation {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-meditation.png') round;
  }
  .icon-fo-Force.Perception {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-perception.png') round;
  }
  .icon-fo-Jedi.Mind.Trick {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/jedi-mind-trick.png') round;
  }
  .icon-fo-Force.Purge {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/force-purge.png') round;
  }
  .icon-fo-Airbase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/airbase.png') round;
  }
  .icon-fo-Air.Transport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/air-transport.png') round;
  }
  .icon-fo-Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/bomber.png') round;
  }
  .icon-fo-Enh.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/enh-bomber.png') round;
  }
  .icon-fo-Adv.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-bomber.png') round;
  }
  .icon-fo-Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/fighter.png') round;
  }
  .icon-fo-Fast.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/fast-fighter.png') round;
  }
  .icon-fo-Adv.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-fighter.png') round;
  }
  .icon-fo-Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/flight-school.png') round;
  }
  .icon-fo-Advanced.Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/advanced-flight-school.png') round;
  }
  .icon-fo-Enlarged.Bomb.Hold {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/enlarged-bomb-hold.png') round;
  }
  .icon-fo-Attacker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/attacker.png') round;
  }
  
  .icon-fo-Efficient.Manufacturing {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/efficient-manufacturing.png') round;
  }
  .icon-fo-Shield.Modifications {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/shield-modifications.png') round;
  }
  .icon-fo-Armored.Platework {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/armored-platework.png') round;
  }
  .icon-fo-Mech.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/mech-factory.png') round;
  }
  .icon-fo-Scout {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/scout.png') round;
  }
  .icon-fo-Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/strike-mech.png') round;
  }
  
  .icon-fo-Hvy.Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-strike-mech.png') round;
  }
  .icon-fo-Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/mech-destroyer.png') round;
  }
  .icon-fo-Hvy.Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-mech-destroyer.png') round;
  }
  .icon-fo-Upgraded.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/upgraded-generator.png') round;
  }
  .icon-fo-Advanced.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-generator.png') round;
  }
  .icon-fo-Transport.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/transport-mech.png') round;
  }
  .icon-fo-Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/assault-mech.png') round;
  }
  .icon-fo-Hvy.Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-assault-mech.png') round;
  }
  .icon-fo-Shipyard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/shipyard.png') round;
  }
  .icon-fo-Utility.Trawler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/utility-trawler.png') round;
  }
  .icon-fo-Aqua.Harvester {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/aqua-harvester.png') round;
  }
  .icon-fo-Sensor.Buoy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/sensor-buoy.png') round;
  }
  .icon-fo-Lt.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/lt-frigate.png') round;
  }
  .icon-fo-Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/frigate.png') round;
  }
  .icon-fo-Adv.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-frigate.png') round;
  }
  .icon-fo-Transport.Ship {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/transport-ship.png') round;
  }
  .icon-fo-Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/destroyer.png') round;
  }
  .icon-fo-Hvy.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-destroyer.png') round;
  }
  .icon-fo-Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/anti-air-destroyer.png') round;
  }
  .icon-fo-Hvy.Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-anti-air-destroyer.png') round;
  }
  .icon-fo-Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/monitor.png') round;
  }
  .icon-fo-Hvy.Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-monitor.png') round;
  }
  .icon-fo-Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/cruiser.png') round;
  }
  .icon-fo-Adv.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-cruiser.png') round;
  }
  .icon-fo-Hvy.Weapons.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-weapons-factory.png') round;
  }
  .icon-fo-Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/pummel.png') round;
  }
  .icon-fo-Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/artillery.png') round;
  }
  .icon-fo-Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/anti-air-mobile.png') round;
  }
  .icon-fo-Hvy.Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-anti-air-mobile.png') round;
  }
  .icon-fo-Strengthened.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/strengthened-frame.png') round;
  }
  .icon-fo-Air.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/air-cruiser.png') round;
  }
  .icon-fo-Cannon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/cannon.png') round;
  }
  .icon-fo-Bothan.Spy.Net {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/bothan-spy-net.png') round;
  }
  .icon-fo-Attack.Programming {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/attack-programming.png') round;
  }
  .icon-fo-Automated.Processes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/automated-processes.png') round;
  }
  .icon-fo-Presidium {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/presidium.png') round;
  }
  .icon-fo-Bounty.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/bounty-hunter.png') round;
  }
  .icon-fo-Fortress {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/fortress.png') round;
  }
  .icon-fo-Worker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/worker.png') round;
  }
  .icon-fo-Medic {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/medic.png') round;
  }
  .icon-fo-Basic.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/basic-training.png') round;
  }
  .icon-fo-Sensor.Beacon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/sensor-beacon.png') round;
  }
  .icon-fo-Sensor.Array {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/sensor-array.png') round;
  }
  .icon-fo-Upgraded.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/upgraded-motivator.png') round;
  }
  .icon-fo-Optimized.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/optimized-motivator.png') round;
  }
  .icon-fo-Bacta.Tanks {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/bacta-tanks.png') round;
  }
  .icon-fo-Command.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/command-center.png') round;
  }
  .icon-fo-War.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/war-center.png') round;
  }
  .icon-fo-Basic.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/basic-armor.png') round;
  }
  .icon-fo-Light.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/light-armor.png') round;
  }
  .icon-fo-Hvy.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-armor.png') round;
  }
  .icon-fo-Elevation.Tracking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/elevation-tracking.png') round;
  }
  .icon-fo-External.Sensor.Pod {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/external-sensor-pod.png') round;
  }
  .icon-fo-Targeting.Sensor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/targeting-sensor.png') round;
  }
  .icon-fo-Light.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/light-plating.png') round;
  }
  .icon-fo-Medium.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/medium-plating.png') round;
  }
  .icon-fo-Hvy.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-plating.png') round;
  }
  .icon-fo-Primary.Focusing.Coils {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/primary-focusing-coils.png') round;
  }
  .icon-fo-Cooling.Sleeves {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/cooling-sleeves.png') round;
  }
  .icon-fo-Advanced.Power.Pack {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/advanced-power-pack.png') round;
  }
  .icon-fo-Redesigned.Specifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/redesigned-specifications.png') round;
  }
  .icon-fo-Grenadier.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/grenadier-training.png') round;
  }
  .icon-fo-Advanced.Scanning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/advanced-scanning.png') round;
  }
  .icon-fo-Spaceport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/spaceport.png') round;
  }
  .icon-fo-Cargo.Hovercraft {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/cargo-hovercraft.png') round;
  }
  .icon-fo-Hutt.Endorsement {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hutt-endorsement.png') round;
  }
  .icon-fo-Galactic.Banking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/galactic-banking.png') round;
  }
  .icon-fo-Galactic.Trade.Comm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/galactic-trade-commission.png') round;
  }
  .icon-fo-Holonet.Tranceiver {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/holonet-tranceiver.png') round;
  }
  .icon-fo-Cargo.Freighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/cargo-freighter.png') round;
  }
  .icon-fo-Research.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/research-center.png') round;
  }
  .icon-fo-Durasteel.Suppt.Beams {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/durasteel-support-beams.png') round;
  }
  .icon-fo-Permacite.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/permacite-plating.png') round;
  }
  .icon-fo-Ion.Accu.Accelerator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/ion-accu-accelerator.png') round;
  }
  .icon-fo-Rotation.Bearings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/rotation-bearings.png') round;
  }
  .icon-fo-Hvy.Weapons.Engineers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-weapons-engineers.png') round;
  }
  .icon-fo-Power.Calibrator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/power-calibrator.png') round;
  }
  .icon-fo-Tracking.and.Target.Comptr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/tracking-and-target-computers.png') round;
  }
  .icon-fo-AA.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/aa-battery.png') round;
  }
  .icon-fo-Homing.Sensors {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/homing-sensors.png') round;
  }
  .icon-fo-Carbon.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/carbon-proc-ctr.png') round;
  }
  .icon-fo-Handheld.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/handheld-carbon-extr.png') round;
  }
  .icon-fo-Enhanced.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/enhanced-carbon-extr.png') round;
  }
  .icon-fo-Hvy.Duty.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-duty-carbon-extr.png') round;
  }
  .icon-fo-Food.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/food-proc-ctr.png') round;
  }
  .icon-fo-Irrigation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/irrigation.png') round;
  }
  .icon-fo-Farm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/farm.png') round;
  }
  .icon-fo-Harvesting.Program {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/harvesting-program.png') round;
  }
  .icon-fo-Adv.Harvest.Prgm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-harvest-program.png') round;
  }
  .icon-fo-Animal.Nursery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/animal-nursery.png') round;
  }
  .icon-fo-Stimulants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/stimulants.png') round;
  }
  .icon-fo-Genetics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/genetics.png') round;
  }
  .icon-fo-Cloning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/cloning.png') round;
  }
  .icon-fo-Nova.Crystal.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/nova-proc-ctr.png') round;
  }
  .icon-fo-Ore.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/ore-proc-ctr.png') round;
  }
  .icon-fo-Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/prefab-shelter.png') round;
  }
  .icon-fo-Gate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/gate.png') round;
  }
  .icon-fo-Light.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/light-wall.png') round;
  }
  .icon-fo-Medium.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/medium-wall.png') round;
  }
  .icon-fo-Shield.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/shield-wall.png') round;
  }
  .icon-fo-Hvy.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-wall.png') round;
  }
  .icon-fo-Monument {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/monument.png') round;
  }
  .icon-fo-Shield.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/shield-generator.png') round;
  }
  .icon-fo-Strengthened.Superstructure {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/strengthened-superstructure.png') round;
  }
  .icon-fo-Supercondctg.Shields {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/superconducting-shields.png') round;
  }
  .icon-fo-Light.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/light-turret.png') round;
  }
  .icon-fo-Medium.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/medium-turret.png') round;
  }
  .icon-fo-Advanced.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/advanced-turret.png') round;
  }
  .icon-fo-Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/anti-air-turret.png') round;
  }
  .icon-fo-Adv.Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-anti-air-turret.png') round;
  }
  .icon-fo-Power.Core {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/power-core.png') round;
  }
  .icon-fo-Power.Droid {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/power-droid.png') round;
  }
  .icon-fo-Strengthened.Assembly {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/strengthened-assembly.png') round;
  }
  .icon-fo-Efficient.Buildings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/efficient-buildings.png') round;
  }
  .icon-fo-Power.Core.Shielding {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/power-core-shielding.png') round;
  }
  .icon-fo-Adv.Redesign {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/adv-redesign.png') round;
  }
  .icon-fo-Technicians {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/technicians.png') round;
  }
  .icon-fo-Interceptor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/interceptor.png') round;
  }
  .icon-fo-Hvy.Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-pummel.png') round;
  }
  .icon-fo-Hvy.Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-artillery.png') round;
  }
  .icon-fo-Anti-Air.Retrofit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/anti-air-retrofit.png') round;
  }
  .icon-fo-Sentry.Post {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/sentry-post.png') round;
  }
  .icon-fo-Reinforced.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/reinforced-frame.png') round;
  }
  .icon-fo-Mechanics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/mechanics.png') round;
  }
  .icon-fo-Advanced.Propulsion {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/advanced-propulsion.png') round;
  }
  .icon-fo-Redoubled.Efforts {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/redoubled-efforts.png') round;
  }
  .icon-fo-Indoctrination {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/indoctrination.png') round;
  }
  .icon-fo-Conflagrine-14 {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/conflagrine-14.png') round;
  }
  .icon-fo-Jet.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/jet-trooper.png') round;
  }
  .icon-fo-Hvy.Jet.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/hvy-jet-trooper.png') round;
  }
  .icon-fo-Advanced.Torpedoes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/advanced-torpedoes.png') round;
  }
  .icon-fo-Auxiliary.Power.Cells {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/auxiliary-power-cells.png') round;
  }
  
  .icon-fo-Mine {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/mine.png') round;
  }
  .icon-fo-Anti-Air.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679212/fo/anti-air-battery.png') round;
  }