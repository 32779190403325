// confederacy
.icon-cf-Troop.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/troop-center.png') round;
  }
  .icon-cf-Trooper.Recruit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/trooper-recruit.png') round;
  }
  .icon-cf-Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/trooper.png') round;
  }
  .icon-cf-Hvy\..Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-trooper.png') round;
  }
  .icon-cf-Repeater.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/repeater-trooper.png') round;
  }
  .icon-cf-Grenade.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/grenade-trooper.png') round;
  }
  .icon-cf-Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/anti-air-trooper.png') round;
  }
  .icon-cf-Hvy\..Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-anti-air-trooper.png') round;
  }
  .icon-cf-Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/mounted-trooper.png') round;
  }
  .icon-cf-Hvy\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-mounted-trooper.png') round;
  }
  .icon-cf-Adv\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-mounted-trooper.png') round;
  }
  .icon-cf-Macro.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/macro-binoculars.png') round;
  }
  .icon-cf-Lighter.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/lighter-armor.png') round;
  }
  .icon-cf-Plasma.Detonators {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/plasma-detonators.png') round;
  }
  .icon-cf-Dexterity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/dexterity.png') round;
  }
  .icon-cf-Droid.Assistants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/droid-assistants.png') round;
  }
  .icon-cf-Explosive.Yields.Increase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/explosive-yields-increased.png') round;
  }
  .icon-cf-Portable.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/portable-scanners.png') round;
  }
  .icon-cf-Integrated.Rangefinder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/integrated-rangefinder.png') round;
  }
  .icon-cf-Sith.Temple {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sith-temple.png') round;
  }
  .icon-cf-Sith.Apprentice {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sith-apprentice.png') round;
  }
  .icon-cf-Sith.Knight {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sith-knight.png') round;
  }
  .icon-cf-Sith.Master {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sith-master.png') round;
  }
  .icon-cf-Force.Stamina {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/force-stamina.png') round;
  }
  .icon-cf-Force.Strong {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/force-strong.png') round;
  }
  .icon-cf-Force.Agility {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/force-agility.png') round;
  }
  .icon-cf-Faith.in.the.Force {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/faith-in-the-force.png') round;
  }
  .icon-cf-Force.Concentration {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/force-concentration.png') round;
  }
  .icon-cf-Force.Meditation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/force-meditation.png') round;
  }
  .icon-cf-Force.Perception {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/force-perception.png') round;
  }
  .icon-cf-Jedi.Mind.Trick {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/jedi-mind-trick.png') round;
  }
  .icon-cf-Force.Purge {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/force-purge.png') round;
  }
  .icon-cf-Airbase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/airbase.png') round;
  }
  .icon-cf-Air.Transport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/air-transport.png') round;
  }
  .icon-cf-Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/bomber.png') round;
  }
  .icon-cf-Enh.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/enh-bomber.png') round;
  }
  .icon-cf-Adv.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-bomber.png') round;
  }
  .icon-cf-Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/fighter.png') round;
  }
  .icon-cf-Fast.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/fast-fighter.png') round;
  }
  .icon-cf-Adv.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-fighter.png') round;
  }
  .icon-cf-Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/flight-school.png') round;
  }
  .icon-cf-Advanced.Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/advanced-flight-school.png') round;
  }
  .icon-cf-Enlarged.Bomb.Hold {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/enlarged-bomb-hold.png') round;
  }
  .icon-cf-Attacker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/attacker.png') round;
  }
  
  .icon-cf-Efficient.Manufacturing {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/efficient-manufacturing.png') round;
  }
  .icon-cf-Shield.Modifications {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/shield-modifications.png') round;
  }
  .icon-cf-Armored.Platework {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/armored-platework.png') round;
  }
  .icon-cf-Mech.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/mech-factory.png') round;
  }
  .icon-cf-Scout {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/scout.png') round;
  }
  .icon-cf-Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/strike-mech.png') round;
  }
  
  .icon-cf-Hvy.Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-strike-mech.png') round;
  }
  .icon-cf-Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/mech-destroyer.png') round;
  }
  .icon-cf-Hvy.Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-mech-destroyer.png') round;
  }
  .icon-cf-Upgraded.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/upgraded-generator.png') round;
  }
  .icon-cf-Advanced.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-generator.png') round;
  }
  .icon-cf-Transport.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/transport-mech.png') round;
  }
  .icon-cf-Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/assault-mech.png') round;
  }
  .icon-cf-Hvy.Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-assault-mech.png') round;
  }
  .icon-cf-Shipyard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1631035705/cf/shipyard.png') round;
  }
  .icon-cf-Utility.Trawler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/utility-trawler.png') round;
  }
  .icon-cf-Aqua.Harvester {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/aqua-harvester.png') round;
  }
  .icon-cf-Sensor.Buoy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sensor-buoy.png') round;
  }
  .icon-cf-Lt.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/lt-frigate.png') round;
  }
  .icon-cf-Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/frigate.png') round;
  }
  .icon-cf-Adv.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-frigate.png') round;
  }
  .icon-cf-Transport.Ship {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/transport-ship.png') round;
  }
  .icon-cf-Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/destroyer.png') round;
  }
  .icon-cf-Hvy.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-destroyer.png') round;
  }
  .icon-cf-Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/anti-air-destroyer.png') round;
  }
  .icon-cf-Hvy.Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-anti-air-destroyer.png') round;
  }
  .icon-cf-Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/monitor.png') round;
  }
  .icon-cf-Hvy.Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-monitor.png') round;
  }
  .icon-cf-Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/cruiser.png') round;
  }
  .icon-cf-Adv.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-cruiser.png') round;
  }
  .icon-cf-Hvy.Weapons.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-weapons-factory.png') round;
  }
  .icon-cf-Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/pummel.png') round;
  }
  .icon-cf-Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/artillery.png') round;
  }
  .icon-cf-Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/anti-air-mobile.png') round;
  }
  .icon-cf-Hvy.Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-anti-air-mobile.png') round;
  }
  .icon-cf-Strengthened.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/strengthened-frame.png') round;
  }
  .icon-cf-Air.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/air-cruiser.png') round;
  }
  .icon-cf-Cannon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/cannon.png') round;
  }
  .icon-cf-Bothan.Spy.Net {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/bothan-spy-net.png') round;
  }
  .icon-cf-Attack.Programming {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/attack-programming.png') round;
  }
  .icon-cf-Automated.Processes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/automated-processes.png') round;
  }
  .icon-cf-Presidium {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/presidium.png') round;
  }
  .icon-cf-Bounty.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/bounty-hunter.png') round;
  }
  .icon-cf-Fortress {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1631035705/cf/fortress.png') round;
  }
  .icon-cf-Worker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/worker.png') round;
  }
  .icon-cf-Medic {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/medic.png') round;
  }
  .icon-cf-Basic.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/basic-training.png') round;
  }
  .icon-cf-Sensor.Beacon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sensor-beacon.png') round;
  }
  .icon-cf-Sensor.Array {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sensor-array.png') round;
  }
  .icon-cf-Upgraded.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/upgraded-motivator.png') round;
  }
  .icon-cf-Optimized.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/optimized-motivator.png') round;
  }
  .icon-cf-Bacta.Tanks {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/bacta-tanks.png') round;
  }
  .icon-cf-Command.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/command-center.png') round;
  }
  .icon-cf-War.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/war-center.png') round;
  }
  .icon-cf-Basic.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/basic-armor.png') round;
  }
  .icon-cf-Light.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/light-armor.png') round;
  }
  .icon-cf-Hvy.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-armor.png') round;
  }
  .icon-cf-Elevation.Tracking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/elevation-tracking.png') round;
  }
  .icon-cf-External.Sensor.Pod {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/external-sensor-pod.png') round;
  }
  .icon-cf-Targeting.Sensor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/targeting-sensor.png') round;
  }
  .icon-cf-Light.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/light-plating.png') round;
  }
  .icon-cf-Medium.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/medium-plating.png') round;
  }
  .icon-cf-Hvy.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-plating.png') round;
  }
  .icon-cf-Primary.Focusing.Coils {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/primary-focusing-coils.png') round;
  }
  .icon-cf-Cooling.Sleeves {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/cooling-sleeves.png') round;
  }
  .icon-cf-Advanced.Power.Pack {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/advanced-power-pack.png') round;
  }
  .icon-cf-Redesigned.Specifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/redesigned-specifications.png') round;
  }
  .icon-cf-Grenadier.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/grenadier-training.png') round;
  }
  .icon-cf-Advanced.Scanning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/advanced-scanning.png') round;
  }
  .icon-cf-Spaceport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1631035705/cf/spaceport.png') round;
  }
  .icon-cf-Cargo.Hovercraft {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/cargo-hovercraft.png') round;
  }
  .icon-cf-Hutt.Endorsement {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hutt-endorsement.png') round;
  }
  .icon-cf-Galactic.Banking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/galactic-banking.png') round;
  }
  .icon-cf-Galactic.Trade.Comm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/galactic-trade-commission.png') round;
  }
  .icon-cf-Holonet.Tranceiver {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/holonet-tranceiver.png') round;
  }
  .icon-cf-Cargo.Freighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/cargo-freighter.png') round;
  }
  .icon-cf-Research.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/research-center.png') round;
  }
  .icon-cf-Durasteel.Suppt.Beams {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/durasteel-support-beams.png') round;
  }
  .icon-cf-Permacite.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/permacite-plating.png') round;
  }
  .icon-cf-Ion.Accu.Accelerator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/ion-accu-accelerator.png') round;
  }
  .icon-cf-Rotation.Bearings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/rotation-bearings.png') round;
  }
  .icon-cf-Hvy.Weapons.Engineers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-weapons-engineers.png') round;
  }
  .icon-cf-Power.Calibrator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/power-calibrator.png') round;
  }
  .icon-cf-Tracking.and.Target.Comptr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/tracking-and-target-computers.png') round;
  }
  .icon-cf-AA.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/aa-battery.png') round;
  }
  .icon-cf-Homing.Sensors {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/homing-sensors.png') round;
  }
  .icon-cf-Carbon.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/carbon-proc-ctr.png') round;
  }
  .icon-cf-Handheld.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/handheld-carbon-extr.png') round;
  }
  .icon-cf-Enhanced.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/enhanced-carbon-extr.png') round;
  }
  .icon-cf-Hvy.Duty.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-duty-carbon-extr.png') round;
  }
  .icon-cf-Food.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/food-proc-ctr.png') round;
  }
  .icon-cf-Irrigation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/irrigation.png') round;
  }
  .icon-cf-Farm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/farm.png') round;
  }
  .icon-cf-Harvesting.Program {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/harvesting-program.png') round;
  }
  .icon-cf-Adv.Harvest.Prgm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-harvest-program.png') round;
  }
  .icon-cf-Animal.Nursery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/animal-nursery.png') round;
  }
  .icon-cf-Stimulants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/stimulants.png') round;
  }
  .icon-cf-Genetics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/genetics.png') round;
  }
  .icon-cf-Cloning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/cloning.png') round;
  }
  .icon-cf-Nova.Crystal.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/nova-proc-ctr.png') round;
  }
  .icon-cf-Ore.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/ore-proc-ctr.png') round;
  }
  .icon-cf-Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/prefab-shelter.png') round;
  }
  .icon-cf-Gate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/gate.png') round;
  }
  .icon-cf-Light.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/light-wall.png') round;
  }
  .icon-cf-Medium.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/medium-wall.png') round;
  }
  .icon-cf-Shield.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/shield-wall.png') round;
  }
  .icon-cf-Hvy.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-wall.png') round;
  }
  .icon-cf-Monument {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/monument.png') round;
  }
  .icon-cf-Shield.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/shield-generator.png') round;
  }
  .icon-cf-Strengthened.Superstructure {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/strengthened-superstructure.png') round;
  }
  .icon-cf-Supercondctg.Shields {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/superconducting-shields.png') round;
  }
  .icon-cf-Light.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/light-turret.png') round;
  }
  .icon-cf-Medium.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/medium-turret.png') round;
  }
  .icon-cf-Advanced.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/advanced-turret.png') round;
  }
  .icon-cf-Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/anti-air-turret.png') round;
  }
  .icon-cf-Adv.Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-anti-air-turret.png') round;
  }
  .icon-cf-Power.Core {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/power-core.png') round;
  }
  .icon-cf-Power.Droid {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/power-droid.png') round;
  }
  .icon-cf-Strengthened.Assembly {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/strengthened-assembly.png') round;
  }
  .icon-cf-Efficient.Buildings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/efficient-buildings.png') round;
  }
  .icon-cf-Power.Core.Shielding {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/power-core-shielding.png') round;
  }
  .icon-cf-Adv.Redesign {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/adv-redesign.png') round;
  }
  .icon-cf-Technicians {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/technicians.png') round;
  }
  .icon-cf-Interceptor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/interceptor.png') round;
  }
  .icon-cf-Hvy.Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-pummel.png') round;
  }
  .icon-cf-Hvy.Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/hvy-artillery.png') round;
  }
  .icon-cf-Anti-Air.Retrofit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/anti-air-retrofit.png') round;
  }
  .icon-cf-Sentry.Post {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/sentry-post.png') round;
  }
  .icon-cf-Reinforced.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/reinforced-frame.png') round;
  }
  .icon-cf-Mechanics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/mechanics.png') round;
  }
  .icon-cf-Advanced.Propulsion {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/advanced-propulsion.png') round;
  }
  .icon-cf-Redoubled.Efforts {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/redoubled-efforts.png') round;
  }
  .icon-cf-Battlefield.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/battlefield-scanners.png') round;
  }
  .icon-cf-Droid.Upgrades {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/droid-upgrades.png') round;
  }
  
  .icon-cf-Confederacy.Alliance {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/confederacy-alliance.png') round;
  }
  .icon-cf-Fusion.Extractor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/fusion-extractor-nova.png') round;
  }
  .icon-cf-Magna.Guard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/Magna-guard.png') round;
  }
  .icon-cf-Elite.Magna.Guard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678762/cf/elite-Magna-guard.png') round;
  }
  .icon-cf-Force.Influence {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-influence.png') round;
  }
  .icon-cf-Mine {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/cf/mine.png') round;
  }
  .icon-cf-Anti-Air.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/cf/anti-air-battery.png') round;
  }