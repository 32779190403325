// zann consortium

.icon-za-Troop.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/troop-center.png') round;
  }
  .icon-za-Trooper.Recruit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/trooper-recruit.png') round;
  }
  .icon-za-Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/trooper.png') round;
  }
  .icon-za-Hvy\..Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-trooper.png') round;
  }
  .icon-za-Repeater.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630932336/za/repeater-trooper.png') round;
  }
  .icon-za-Grenade.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630932336/za/grenade-trooper.png') round;
  }
  .icon-za-Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/anti-air-trooper.png') round;
  }
  .icon-za-Hvy\..Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-anti-air-trooper.png') round;
  }
  .icon-za-Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/mounted-trooper.png') round;
  }
  .icon-za-Hvy\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-mounted-trooper.png') round;
  }
  .icon-za-Adv\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-mounted-trooper.png') round;
  }
  .icon-za-Macro.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/macro-binoculars.png') round;
  }
  .icon-za-Lighter.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/lighter-armor.png') round;
  }
  .icon-za-Plasma.Detonators {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/plasma-detonators.png') round;
  }
  .icon-za-Dexterity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/dexterity.png') round;
  }
  .icon-za-Droid.Assistants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/droid-assistants.png') round;
  }
  .icon-za-Explosive.Yields.Increase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/explosive-yields-increased.png') round;
  }
  .icon-za-Portable.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/portable-scanners.png') round;
  }
  .icon-za-Integrated.Rangefinder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/integrated-rangefinder.png') round;
  }
  .icon-za-Nightsister.Temple {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/nightsister-temple.png') round;
  }
  .icon-za-Nightsister.Initiate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/nightsister-initiate.png') round;
  }
  .icon-za-Nightsister.Warrior {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/nightsister-warrior.png') round;
  }
  .icon-za-Nightmother {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/nightmother.png') round;
  }
  .icon-za-Nightsister.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630864595/za/nightsister-hunter.png') round;
  }
  .icon-za-Force.Stamina {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-stamina.png') round;
  }
  .icon-za-Force.Influence {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-influence.png') round;
  }
  .icon-za-Force.Strong {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-strong.png') round;
  }
  .icon-za-Force.Agility {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-agility.png') round;
  }
  .icon-za-Faith.in.the.Force {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/faith-in-the-force.png') round;
  }
  .icon-za-Force.Concentration {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-concentration.png') round;
  }
  .icon-za-Force.Meditation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-meditation.png') round;
  }
  .icon-za-Force.Perception {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-perception.png') round;
  }
  .icon-za-Jedi.Mind.Trick {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/jedi-mind-trick.png') round;
  }
  .icon-za-Force.Purge {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/force-purge.png') round;
  }
  .icon-za-Airbase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/airbase.png') round;
  }
  .icon-za-Air.Transport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/air-transport.png') round;
  }
  .icon-za-Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/bomber.png') round;
  }
  .icon-za-Enh.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/enh-bomber.png') round;
  }
  .icon-za-Adv.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-bomber.png') round;
  }
  .icon-za-Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/fighter.png') round;
  }
  .icon-za-Fast.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/fast-fighter.png') round;
  }
  .icon-za-Adv.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-fighter.png') round;
  }
  .icon-za-Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/flight-school.png') round;
  }
  .icon-za-Advanced.Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/advanced-flight-school.png') round;
  }
  .icon-za-Enlarged.Bomb.Hold {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/enlarged-bomb-hold.png') round;
  }
  .icon-za-Attacker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/attacker.png') round;
  }
  
  .icon-za-Efficient.Manufacturing {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/efficient-manufacturing.png') round;
  }
  .icon-za-Shield.Modifications {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/shield-modifications.png') round;
  }
  .icon-za-Armored.Platework {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/armored-platework.png') round;
  }
  .icon-za-Mech.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/mech-factory.png') round;
  }
  .icon-za-Scout {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/scout.png') round;
  }
  .icon-za-Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/strike-mech.png') round;
  }
  
  .icon-za-Hvy.Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-strike-mech.png') round;
  }
  .icon-za-Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/mech-destroyer.png') round;
  }
  .icon-za-Hvy.Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-mech-destroyer.png') round;
  }
  .icon-za-Upgraded.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/upgraded-generator.png') round;
  }
  .icon-za-Advanced.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-generator.png') round;
  }
  .icon-za-Transport.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/transport-mech.png') round;
  }
  .icon-za-Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/assault-mech.png') round;
  }
  .icon-za-Hvy.Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-assault-mech.png') round;
  }
  .icon-za-Shipyard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/shipyard.png') round;
  }
  .icon-za-Utility.Trawler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/utility-trawler.png') round;
  }
  .icon-za-Aqua.Harvester {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/aqua-harvester.png') round;
  }
  .icon-za-Sensor.Buoy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/sensor-buoy.png') round;
  }
  .icon-za-Lt.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/lt-frigate.png') round;
  }
  .icon-za-Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/frigate.png') round;
  }
  .icon-za-Adv.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-frigate.png') round;
  }
  .icon-za-Transport.Ship {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/transport-ship.png') round;
  }
  .icon-za-Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/destroyer.png') round;
  }
  .icon-za-Hvy.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-destroyer.png') round;
  }
  .icon-za-Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/anti-air-destroyer.png') round;
  }
  .icon-za-Hvy.Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-anti-air-destroyer.png') round;
  }
  .icon-za-Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/monitor.png') round;
  }
  .icon-za-Hvy.Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-monitor.png') round;
  }
  .icon-za-Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/cruiser.png') round;
  }
  .icon-za-Adv.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-cruiser.png') round;
  }
  .icon-za-Hvy.Weapons.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-weapons-factory.png') round;
  }
  .icon-za-Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/pummel.png') round;
  }
  .icon-za-Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/artillery.png') round;
  }
  .icon-za-Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/anti-air-mobile.png') round;
  }
  .icon-za-Hvy.Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-anti-air-mobile.png') round;
  }
  .icon-za-Strengthened.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/strengthened-frame.png') round;
  }
  .icon-za-Air.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/air-cruiser.png') round;
  }
  .icon-za-Cannon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1631565258/za/cannon.png') round;
  }
  .icon-za-Bothan.Spy.Net {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/bothan-spy-net.png') round;
  }
  .icon-za-Attack.Programming {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/attack-programming.png') round;
  }
  .icon-za-Automated.Processes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/automated-processes.png') round;
  }
  .icon-za-Presidium {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/presidium.png') round;
  }
  .icon-za-Bounty.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/bounty-hunter.png') round;
  }
  .icon-za-Fortress {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/fortress.png') round;
  }
  .icon-za-Worker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/worker.png') round;
  }
  .icon-za-Medic {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/medic.png') round;
  }
  .icon-za-Basic.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/basic-training.png') round;
  }
  .icon-za-Sensor.Beacon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/sensor-beacon.png') round;
  }
  .icon-za-Sensor.Array {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/sensor-array.png') round;
  }
  .icon-za-Upgraded.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/upgraded-motivator.png') round;
  }
  .icon-za-Optimized.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/optimized-motivator.png') round;
  }
  .icon-za-Bacta.Tanks {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/bacta-tanks.png') round;
  }
  .icon-za-Command.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/command-center.png') round;
  }
  .icon-za-War.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/war-center.png') round;
  }
  .icon-za-Basic.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/basic-armor.png') round;
  }
  .icon-za-Light.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/light-armor.png') round;
  }
  .icon-za-Hvy.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-armor.png') round;
  }
  .icon-za-Elevation.Tracking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/elevation-tracking.png') round;
  }
  .icon-za-External.Sensor.Pod {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/external-sensor-pod.png') round;
  }
  .icon-za-Targeting.Sensor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/targeting-sensor.png') round;
  }
  .icon-za-Light.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/light-plating.png') round;
  }
  .icon-za-Medium.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/medium-plating.png') round;
  }
  .icon-za-Hvy.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-plating.png') round;
  }
  .icon-za-Primary.Focusing.Coils {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/primary-focusing-coils.png') round;
  }
  .icon-za-Cooling.Sleeves {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/cooling-sleeves.png') round;
  }
  .icon-za-Advanced.Power.Pack {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/advanced-power-pack.png') round;
  }
  .icon-za-Redesigned.Specifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/redesigned-specifications.png') round;
  }
  .icon-za-Grenadier.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/grenadier-training.png') round;
  }
  .icon-za-Advanced.Scanning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/advanced-scanning.png') round;
  }
  .icon-za-Spaceport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/spaceport.png') round;
  }
  .icon-za-Cargo.Hovercraft {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/cargo-hovercraft.png') round;
  }
  .icon-za-Hutt.Endorsement {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hutt-endorsement.png') round;
  }
  .icon-za-Galactic.Banking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/galactic-banking.png') round;
  }
  .icon-za-Galactic.Trade.Comm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/galactic-trade-commission.png') round;
  }
  .icon-za-Holonet.Tranceiver {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/holonet-tranceiver.png') round;
  }
  .icon-za-Cargo.Freighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/cargo-freighter.png') round;
  }
  .icon-za-Research.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/research-center.png') round;
  }
  .icon-za-Durasteel.Suppt.Beams {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/durasteel-support-beams.png') round;
  }
  .icon-za-Permacite.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/permacite-plating.png') round;
  }
  .icon-za-Ion.Accu.Accelerator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/ion-accu-accelerator.png') round;
  }
  .icon-za-Rotation.Bearings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/rotation-bearings.png') round;
  }
  .icon-za-Hvy.Weapons.Engineers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-weapons-engineers.png') round;
  }
  .icon-za-Power.Calibrator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/power-calibrator.png') round;
  }
  .icon-za-Tracking.and.Target.Comptr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/tracking-and-target-computers.png') round;
  }
  .icon-za-AA.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/aa-battery.png') round;
  }
  .icon-za-Homing.Sensors {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/homing-sensors.png') round;
  }
  .icon-za-Carbon.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/carbon-proc-ctr.png') round;
  }
  .icon-za-Handheld.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/handheld-carbon-extr.png') round;
  }
  .icon-za-Enhanced.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/enhanced-carbon-extr.png') round;
  }
  .icon-za-Hvy.Duty.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-duty-carbon-extr.png') round;
  }
  .icon-za-Food.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/food-proc-ctr.png') round;
  }
  .icon-za-Irrigation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/irrigation.png') round;
  }
  .icon-za-Farm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/farm.png') round;
  }
  .icon-za-Harvesting.Program {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/harvesting-program.png') round;
  }
  .icon-za-Adv.Harvest.Prgm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-harvest-program.png') round;
  }
  .icon-za-Animal.Nursery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/animal-nursery.png') round;
  }
  .icon-za-Stimulants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/stimulants.png') round;
  }
  .icon-za-Genetics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/genetics.png') round;
  }
  .icon-za-Cloning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/cloning.png') round;
  }
  .icon-za-Nova.Crystal.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/nova-proc-ctr.png') round;
  }
  .icon-za-Ore.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/ore-proc-ctr.png') round;
  }
  .icon-za-Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/prefab-shelter.png') round;
  }
  .icon-za-Gate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/gate.png') round;
  }
  .icon-za-Light.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/light-wall.png') round;
  }
  .icon-za-Medium.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/medium-wall.png') round;
  }
  .icon-za-Shield.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/shield-wall.png') round;
  }
  .icon-za-Hvy.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-wall.png') round;
  }
  .icon-za-Monument {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/monument.png') round;
  }
  .icon-za-Shield.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/shield-generator.png') round;
  }
  .icon-za-Strengthened.Superstructure {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/strengthened-superstructure.png') round;
  }
  .icon-za-Supercondctg.Shields {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/superconducting-shields.png') round;
  }
  .icon-za-Light.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/light-turret.png') round;
  }
  .icon-za-Medium.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/medium-turret.png') round;
  }
  .icon-za-Advanced.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/advanced-turret.png') round;
  }
  .icon-za-Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/anti-air-turret.png') round;
  }
  .icon-za-Adv.Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-anti-air-turret.png') round;
  }
  .icon-za-Power.Core {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/power-core.png') round;
  }
  .icon-za-Power.Droid {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/power-droid.png') round;
  }
  .icon-za-Strengthened.Assembly {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/strengthened-assembly.png') round;
  }
  .icon-za-Efficient.Buildings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/efficient-buildings.png') round;
  }
  .icon-za-Power.Core.Shielding {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/power-core-shielding.png') round;
  }
  .icon-za-Adv.Redesign {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/adv-redesign.png') round;
  }
  .icon-za-Technicians {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/technicians.png') round;
  }
  .icon-za-Interceptor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/interceptor.png') round;
  }
  .icon-za-Hvy.Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-pummel.png') round;
  }
  .icon-za-Hvy.Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/hvy-artillery.png') round;
  }
  .icon-za-Anti-Air.Retrofit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/anti-air-retrofit.png') round;
  }
  .icon-za-Sentry.Post {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/sentry-post.png') round;
  }
  .icon-za-Reinforced.Frame {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/reinforced-frame.png') round;
  }
  .icon-za-Mechanics {
        background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/mechanics.png') round;
  }
  .icon-za-Advanced.Propulsion {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/advanced-propulsion.png') round;
  }
  .icon-za-Redoubled.Efforts {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/redoubled-efforts.png') round;
  }
  .icon-za-Phased.Pulse.Cannons {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/phased-pulse-cannons.png') round;
  }
  .icon-za-Defiler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/defiler.png') round;
  }
  .icon-za-Veteran.Defiler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/veteran-defiler.png') round;
  }
  .icon-za-Piracy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/piracy.png') round;
  }
  .icon-za-Vornskr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/vornskr.png') round;
  }
  .icon-za-Black.Market.Components {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679130/za/black-market-components.png') round;
  }
  .icon-za-Mine {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/za/mine.png') round;
  }
  .icon-za-Anti-Air.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/za/anti-air-battery.png') round;
  }