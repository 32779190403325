.icon-wo-Troop.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/troop-center.png') round;
}
.icon-wo-Trooper.Recruit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/trooper-recruit.png') round;
}
.icon-wo-Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/trooper.png') round;
}
.icon-wo-Hvy\..Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-trooper.png') round;
}
.icon-wo-Repeater.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/repeater-trooper.png') round;
}
.icon-wo-Grenade.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/grenade-trooper.png') round;
}
.icon-wo-Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/anti-air-trooper.png') round;
}

.icon-wo-Hvy\..Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-anti-air-trooper.png') round;
}
.icon-wo-Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/mounted-trooper.png') round;
}
.icon-wo-Hvy\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-mounted-trooper.png') round;
}
.icon-wo-Adv\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-mounted-trooper.png') round;
}

.icon-wo-Macro.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/macro-binoculars.png') round;
}

.icon-wo-Lighter.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/lighter-armor.png') round;
}
.icon-wo-Plasma.Detonators {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/plasma-detonators.png') round;
}
.icon-wo-Dexterity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/dexterity.png') round;
}
.icon-wo-Droid.Assistants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/droid-assistants.png') round;
}

.icon-wo-Explosive.Yields.Increase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/explosive-yields-increased.png') round;
}

.icon-wo-Portable.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/portable-scanners.png') round;
}

.icon-wo-Integrated.Rangefinder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/integrated-rangefinder.png') round;
}

.icon-wo-Jedi.Temple {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/jedi-temple.png') round;
}

.icon-wo-Jedi.Padawan {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/jedi-padawan.png') round;
}
.icon-wo-Jedi.Knight {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/jedi-knight.png') round;
}
.icon-wo-Jedi.Master {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/jedi-master.png') round;
}
.icon-wo-Force.Stamina {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-stamina.png') round;
}
.icon-wo-Force.Strong {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-strong.png') round;
}
.icon-wo-Force.Agility {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-agility.png') round;
}
.icon-wo-Faith.in.the.Force {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/faith-in-the-force.png') round;
}
.icon-wo-Force.Concentration {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-concentration.png') round;
}
.icon-wo-Force.Meditation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-meditation.png') round;
}
.icon-wo-Force.Perception {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-perception.png') round;
}

.icon-wo-Jedi.Mind.Trick {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/jedi-mind-trick.png') round;
}

.icon-wo-Force.Purge {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-purge.png') round;
}

.icon-wo-Airbase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/airbase.png') round;
}
.icon-wo-Air.Transport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/air-transport.png') round;
}
.icon-wo-Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/bomber.png') round;
}
.icon-wo-Enh.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/enh-bomber.png') round;
}
.icon-wo-Adv.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-bomber.png') round;
}
.icon-wo-Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/fighter.png') round;
}
.icon-wo-Fast.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/fast-fighter.png') round;
}
.icon-wo-Adv.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-fighter.png') round;
}
.icon-wo-Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/flight-school.png') round;
}
.icon-wo-Advanced.Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/advanced-flight-school.png') round;
}
.icon-wo-Enlarged.Bomb.Hold {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/enlarged-bomb-hold.png') round;
}
.icon-wo-Attacker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/attacker.png') round;
}
.icon-wo-Efficient.Manufacturing {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/efficient-manufacturing.png') round;
}
.icon-wo-Shield.Modifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/shield-modifications.png') round;
}
.icon-wo-Armored.Platework {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/armored-platework.png') round;
}

.icon-wo-Mech.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/mech-factory.png') round;
}

.icon-wo-Scout {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/scout.png') round;
}

.icon-wo-Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/strike-mech.png') round;
}
.icon-wo-Hvy.Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-strike-mech.png') round;
}
.icon-wo-Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/mech-destroyer.png') round;
}
.icon-wo-Hvy.Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-mech-destroyer.png') round;
}
.icon-wo-Upgraded.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/upgraded-generator.png') round;
}

.icon-wo-Advanced.Generator{
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-generator.png') round;
}
.icon-wo-Transport.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/transport-mech.png') round;
}
.icon-wo-Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/assault-mech.png') round;
}
.icon-wo-Force.Influence {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/force-influence.png') round;
}
.icon-wo-Hvy.Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-assault-mech.png') round;
}

.icon-wo-Shipyard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/shipyard.png') round;
}
.icon-wo-Utility.Trawler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/utility-trawler.png') round;
}
.icon-wo-Aqua.Harvester {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/aqua-harvester.png') round;
}
.icon-wo-Sensor.Buoy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/sensor-buoy.png') round;
}

.icon-wo-Lt.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/lt-frigate.png') round;
}
.icon-wo-Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/frigate.png') round;
}
.icon-wo-Adv.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-frigate.png') round;
}
.icon-wo-Transport.Ship {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/transport-ship.png') round;
}

.icon-wo-Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/destroyer.png') round;
}
.icon-wo-Hvy.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-destroyer.png') round;
}
.icon-wo-Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/anti-air-destroyer.png') round;
}
.icon-wo-Hvy.Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-anti-air-destroyer.png') round;
}

.icon-wo-Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/monitor.png') round;
}
.icon-wo-Hvy.Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-monitor.png') round;
}
.icon-wo-Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/cruiser.png') round;
}
.icon-wo-Adv.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-cruiser.png') round;
}

.icon-wo-Hvy.Weapons.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-weapons-factory.png') round;
}

.icon-wo-Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/pummel.png') round;
}

.icon-wo-Hvy.Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-pummel.png') round;
}

.icon-wo-Anti-Air.Retrofit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/anti-air-retrofit.png') round;
}

.icon-wo-Reinforced.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/reinforced-frame.png') round;
}

.icon-wo-Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/artillery.png') round;
}

.icon-wo-Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/anti-air-mobile.png') round;
}

.icon-wo-Hvy.Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-anti-air-mobile.png') round;
}

.icon-wo-Strengthened.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/strengthened-frame.png') round;
}

.icon-wo-Air.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/air-cruiser.png') round;
}

.icon-wo-Cannon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/cannon.png') round;
}

.icon-wo-Bothan.Spy.Net {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/bothan-spy-net.png') round;
}

.icon-wo-Attack.Programming {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/attack-programming.png') round;
}

.icon-wo-Automated.Processes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/automated-processes.png') round;
}
.icon-wo-Presidium {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/presidium.png') round;
}

.icon-wo-Bounty.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/bounty-hunter.png') round;
}

.icon-wo-Fortress {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/fortress.png') round;
}

.icon-wo-Worker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/worker.png') round;
}

.icon-wo-Medic {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/medic.png') round;
}

.icon-wo-Basic.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/basic-training.png') round;
}

.icon-wo-Sensor.Beacon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/sensor-beacon.png') round;
}

.icon-wo-Sensor.Array {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/sensor-array.png') round;
}

.icon-wo-Upgraded.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/upgraded-motivator.png') round;
}

.icon-wo-Optimized.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/optimized-motivator.png') round;
}

.icon-wo-Bacta.Tanks {
    background:  url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/bacta-tanks.png') round;
}

.icon-wo-Command.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/command-center.png') round;
}

.icon-wo-War.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/war-center.png') round;
}
.icon-wo-Basic.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/basic-armor.png') round;
}
.icon-wo-Light.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/light-armor.png') round;
}
.icon-wo-Hvy.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-armor.png') round;
}
.icon-wo-Elevation.Tracking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/elevation-tracking.png') round;
}
.icon-wo-External.Sensor.Pod {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/external-sensor-pod.png') round;
}
.icon-wo-Targeting.Sensor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/targeting-sensor.png') round;
}
.icon-wo-Light.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/light-plating.png') round;
}
.icon-wo-Medium.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/medium-plating.png') round;
}
.icon-wo-Hvy.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-plating.png') round;
}
.icon-wo-Primary.Focusing.Coils {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/primary-focusing-coils.png') round;
}
.icon-wo-Cooling.Sleeves {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/cooling-sleeves.png') round;
}

.icon-wo-Advanced.Power.Pack {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/advanced-power-pack.png') round;
}
.icon-wo-Redesigned.Specifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/redesigned-specifications.png') round;
}

.icon-wo-Grenadier.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/grenadier-training.png') round;
}
.icon-wo-Advanced.Scanning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/advanced-scanning.png') round;
}

.icon-wo-Spaceport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/spaceport.png') round;
}

.icon-wo-Cargo.Hovercraft {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/cargo-hovercraft.png') round;
}
.icon-wo-Hutt.Endorsement {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hutt-endorsement.png') round;
}

.icon-wo-Galactic.Banking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/galactic-banking.png') round;
}

.icon-wo-Galactic.Trade.Comm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/galactic-trade-commission.png') round;
}
.icon-wo-Holonet.Tranceiver {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/holonet-tranceiver.png') round;
}


.icon-wo-Cargo.Freighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/cargo-freighter.png') round;
}

.icon-wo-Research.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/research-center.png') round;
}

.icon-wo-Durasteel.Suppt.Beams {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/durasteel-support-beams.png') round;
}

.icon-wo-Permacite.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/permacite-plating.png') round;
}

.icon-wo-Ion.Accu.Accelerator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/ion-accu-accelerator.png') round;
}

.icon-wo-Rotation.Bearings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/rotation-bearings.png') round;
}

.icon-wo-Hvy.Weapons.Engineers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-weapons-engineers.png') round;
}

.icon-wo-Power.Calibrator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/power-calibrator.png') round;
}

.icon-wo-Tracking.and.Target.Comptr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/tracking-and-target-computers.png') round;
}
    
.icon-wo-AA.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/aa-battery.png') round;
}

.icon-wo-Homing.Sensors {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/homing-sensors.png') round;
}

.icon-wo-Carbon.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/carbon-proc-ctr.png') round;
}

.icon-wo-Handheld.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/handheld-carbon-extr.png') round;
}

.icon-wo-Enhanced.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/enhanced-carbon-extr.png') round;
}

.icon-wo-Hvy.Duty.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-duty-carbon-extr.png') round;
}

.icon-wo-Food.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/food-proc-ctr.png') round;
}

.icon-wo-Irrigation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/irrigation.png') round;
}
.icon-wo-Farm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/farm.png') round;
}

.icon-wo-Harvesting.Program {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/harvesting-program.png') round;
}


.icon-wo-Adv.Harvest.Prgm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-harvest-program.png') round;
}

.icon-wo-Animal.Nursery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/animal-nursery.png') round;
}
.icon-wo-Stimulants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/stimulants.png') round;
}

.icon-wo-Genetics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/genetics.png') round;
}

.icon-wo-Cloning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/cloning.png') round;
}

.icon-wo-Nova.Crystal.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/nova-proc-ctr.png') round;
}
.icon-nova-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/beamdrill-mining-nova.png') round;
}

.icon-nova-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-duty-beamdrill-nova.png') round;
}

.icon-wo-Ore.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/ore-proc-ctr.png') round;
}

.icon-ore-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/beamdrill-mining-ore.png') round;
}

.icon-ore-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-duty-beamdrill-ore.png') round;
}
.icon-wo-Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/prefab-shelter.png') round;
}
.icon-wo-Gate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/gate.png') round;
}

.icon-wo-Light.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/light-wall.png') round;
}
.icon-wo-Medium.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/medium-wall.png') round;
}
.icon-wo-Shield.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/shield-wall.png') round;
}

.icon-wo-Hvy.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-wall.png') round;
}
.icon-wo-Monument {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/monument.png') round;
}
.icon-wo-Shield.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/shield-generator.png') round;
}

.icon-wo-Strengthened.Superstructure {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/strengthened-superstructure.png') round;
}
.icon-wo-Supercondctg.Shields {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/superconducting-shields.png') round;
}
.icon-wo-Light.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/light-turret.png') round;
}

.icon-wo-Medium.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/medium-turret.png') round;
}

.icon-wo-Advanced.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/advanced-turret.png') round;
}
.icon-wo-Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/anti-air-turret.png') round;
}

.icon-wo-Adv.Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-anti-air-turret.png') round;
}

.icon-wo-Power.Core {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/power-core.png') round;
}
.icon-wo-Power.Droid {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/power-droid.png') round;
}
.icon-wo-Strengthened.Assembly {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/strengthened-assembly.png') round;
}

.icon-wo-Efficient.Buildings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/efficient-buildings.png') round;
}

.icon-wo-Power.Core.Shielding {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/power-core-shielding.png') round;
}
.icon-wo-Mechanics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/mechanics.png') round;
}
.icon-wo-Advanced.Propulsion {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/advanced-propulsion.png') round;
}
.icon-wo-Redoubled.Efforts {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/redoubled-efforts.png') round;
}

.icon-wo-Adv.Redesign {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-redesign.png') round;
}
.icon-wo-Technicians {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/technicians.png') round;
}
.icon-wo-Wookie.Ingenuity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630767713/wo/wookiee-ingenuity.png') round;
}
.icon-wo-Hvy.Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/hvy-artillery.png') round;
}
.icon-wo-Berserker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/berserker.png') round;
}
.icon-wo-Adv.Berserker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/adv-berserker.png') round;
}
.icon-wo-Wookiee.Ingenuity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/wookiee-ingenuity.png') round;
}
.icon-wo-Self.Regeneration {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/self-regeneration.png') round;
}
.icon-wo-Sentry.Post {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/sentry-post.png') round;
}
.icon-wo-Forest.Vision {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/forest-vision.png') round;
}
.icon-wo-Interceptor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630752587/wo/interceptor.png') round;
}

.icon-wo-Mine {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/wo/mine.png') round;
  }
  .icon-wo-Anti-Air.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/wo/anti-air-battery.png') round;
  }