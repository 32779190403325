// resistance 

.icon-rs-Troop.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/troop-center.png') round;
  }
  .icon-rs-Trooper.Recruit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/trooper-recruit.png') round;
  }
  .icon-rs-Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/trooper.png') round;
  }
  .icon-rs-Hvy\..Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-trooper.png') round;
  }
  .icon-rs-Repeater.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/repeater-trooper.png') round;
  }
  .icon-rs-Grenade.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/grenade-trooper.png') round;
  }
  .icon-rs-Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/anti-air-trooper.png') round;
  }
  
  .icon-rs-Hvy\..Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-anti-air-trooper.png') round;
  }
  .icon-rs-Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/mounted-trooper.png') round;
  }
  .icon-rs-Hvy\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-mounted-trooper.png') round;
  }
  .icon-rs-Adv\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-mounted-trooper.png') round;
  }
  
  .icon-rs-Macro.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/macro-binoculars.png') round;
  }
  
  .icon-rs-Lighter.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/lighter-armor.png') round;
  }
  .icon-rs-Plasma.Detonators {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/plasma-detonators.png') round;
  }
  .icon-rs-Dexterity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/dexterity.png') round;
  }
  .icon-rs-Droid.Assistants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/droid-assistants.png') round;
  }
  
  .icon-rs-Explosive.Yields.Increase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/explosive-yields-increased.png') round;
  }
  
  .icon-rs-Portable.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/portable-scanners.png') round;
  }
  
  .icon-rs-Integrated.Rangefinder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/integrated-rangefinder.png') round;
  }
  
  .icon-rs-Jedi.Temple {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/jedi-temple.png') round;
  }
  
  .icon-rs-Jedi.Padawan {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/jedi-padawan.png') round;
  }
  .icon-rs-Jedi.Knight {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/jedi-knight.png') round;
  }
  .icon-rs-Jedi.Master {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/jedi-master.png') round;
  }
  .icon-rs-Force.Stamina {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-stamina.png') round;
  }
  .icon-rs-Force.Strong {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-strong.png') round;
  }
  .icon-rs-Force.Agility {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-agility.png') round;
  }
  .icon-rs-Faith.in.the.Force {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/faith-in-the-force.png') round;
  }
  .icon-rs-Force.Concentration {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-concentration.png') round;
  }
  .icon-rs-Force.Meditation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-meditation.png') round;
  }
  .icon-rs-Force.Perception {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-perception.png') round;
  }
  
  .icon-rs-Jedi.Mind.Trick {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/jedi-mind-trick.png') round;
  }
  
  .icon-rs-Force.Purge {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-purge.png') round;
  }
  
  .icon-rs-Airbase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/airbase.png') round;
  }
  .icon-rs-Air.Transport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/air-transport.png') round;
  }
  .icon-rs-Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/bomber.png') round;
  }
  .icon-rs-Enh.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/enh-bomber.png') round;
  }
  .icon-rs-Adv.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-bomber.png') round;
  }
  .icon-rs-Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/fighter.png') round;
  }
  .icon-rs-Fast.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/fast-fighter.png') round;
  }
  .icon-rs-Adv.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-fighter.png') round;
  }
  .icon-rs-Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/flight-school.png') round;
  }
  .icon-rs-Advanced.Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/advanced-flight-school.png') round;
  }
  .icon-rs-Enlarged.Bomb.Hold {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/enlarged-bomb-hold.png') round;
  }
  .icon-rs-Attacker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/attacker.png') round;
  }
  .icon-rs-Efficient.Manufacturing {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/efficient-manufacturing.png') round;
  }
  .icon-rs-Shield.Modifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/shield-modifications.png') round;
  }
  .icon-rs-Armored.Platework {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/armored-platework.png') round;
  }
  
  .icon-rs-Mech.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/mech-factory.png') round;
  }
  
  .icon-rs-Scout {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/scout.png') round;
  }
  
  .icon-rs-Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/strike-mech.png') round;
  }
  .icon-rs-Hvy.Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-strike-mech.png') round;
  }
  .icon-rs-Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/mech-destroyer.png') round;
  }
  .icon-rs-Hvy.Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-mech-destroyer.png') round;
  }
  .icon-rs-Upgraded.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/upgraded-generator.png') round;
  }
  
  .icon-rs-Adv.Generator{
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-generator.png') round;
  }
  .icon-rs-Transport.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/transport-mech.png') round;
  }
  .icon-rs-Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/assault-mech.png') round;
  }
  .icon-rs-Force.Influence {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/force-influence.png') round;
  }
  .icon-rs-Hvy.Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-assault-mech.png') round;
  }
  
  .icon-rs-Shipyard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/shipyard.png') round;
  }
  .icon-rs-Utility.Trawler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/utility-trawler.png') round;
  }
  .icon-rs-Aqua.Harvester {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/aqua-harvester.png') round;
  }
  .icon-rs-Sensor.Buoy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/sensor-buoy.png') round;
  }
  
  .icon-rs-Lt.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/lt-frigate.png') round;
  }
  .icon-rs-Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/frigate.png') round;
  }
  .icon-rs-Adv.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-frigate.png') round;
  }
  .icon-rs-Transport.Ship {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/transport-ship.png') round;
  }
  
  .icon-rs-Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/destroyer.png') round;
  }
  .icon-rs-Hvy.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-destroyer.png') round;
  }
  .icon-rs-Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/anti-air-destroyer.png') round;
  }
  .icon-rs-Hvy.Anti.Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-anti-air-destroyer.png') round;
  }
  
  .icon-rs-Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/monitor.png') round;
  }
  .icon-rs-Hvy.Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-monitor.png') round;
  }
  .icon-rs-Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/cruiser.png') round;
  }
  .icon-rs-Adv.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-cruiser.png') round;
  }
  
  .icon-rs-Hvy.Weapons.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-weapons-factory.png') round;
  }
  
  .icon-rs-Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/pummel.png') round;
  }
  
  .icon-rs-Hvy.Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-pummel.png') round;
  }
  
  .icon-rs-Anti-Air.Retrofit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/anti-air-retrofit.png') round;
  }
  
  .icon-rs-Reinforced.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/reinforced-frame.png') round;
  }
  
  .icon-rs-Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/artillery.png') round;
  }
  .icon-rs-Hvy.Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-artillery.png') round;
  }
  
  .icon-rs-Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/anti-air-mobile.png') round;
  }
  
  .icon-rs-Hvy.Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-anti-air-mobile.png') round;
  }
  
  .icon-rs-Strengthened.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/strengthened-frame.png') round;
  }
  
  .icon-rs-Air.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/air-cruiser.png') round;
  }
  
  .icon-rs-Cannon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/cannon.png') round;
  }
  
  .icon-rs-Bothan.Spy.Net {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/bothan-spy-net.png') round;
  }
  
  .icon-rs-Attack.Programming {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/attack-programming.png') round;
  }
  
  .icon-rs-Automated.Processes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/automated-processes.png') round;
  }
  .icon-rs-Presidium {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/presidium.png') round;
  }
  
  .icon-rs-Bounty.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/bounty-hunter.png') round;
  }
  
  .icon-rs-Fortress {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/fortress.png') round;
  }
  
  .icon-rs-Worker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/worker.png') round;
  }
  
  .icon-rs-Medic {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/medic.png') round;
  }
  
  .icon-rs-Basic.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/basic-training.png') round;
  }
  
  .icon-rs-Sensor.Beacon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/sensor-beacon.png') round;
  }
  
  .icon-rs-Sensor.Array {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/sensor-array.png') round;
  }
  
  .icon-rs-Upgraded.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/upgraded-motivator.png') round;
  }
  
  .icon-rs-Optimized.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/optimized-motivator.png') round;
  }
  
  .icon-rs-Bacta.Tanks {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/bacta-tanks.png') round;
  }
  
  .icon-rs-Command.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/command-center.png') round;
  }
  
  .icon-rs-War.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/war-center.png') round;
  }
  .icon-rs-Basic.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/basic-armor.png') round;
  }
  .icon-rs-Light.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/light-armor.png') round;
  }
  .icon-rs-Hvy.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-armor.png') round;
  }
  .icon-rs-Elevation.Tracking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/elevation-tracking.png') round;
  }
  .icon-rs-External.Sensor.Pod {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/external-sensor-pod.png') round;
  }
  .icon-rs-Targeting.Sensor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/targeting-sensor.png') round;
  }
  .icon-rs-Light.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/light-plating.png') round;
  }
  .icon-rs-Medium.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/medium-plating.png') round;
  }
  .icon-rs-Hvy.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-plating.png') round;
  }
  .icon-rs-Primary.Focusing.Coils {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/primary-focusing-coils.png') round;
  }
  .icon-rs-Cooling.Sleeves {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/cooling-sleeves.png') round;
  }
  
  .icon-rs-Advanced.Power.Pack {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/advanced-power-pack.png') round;
  }
  .icon-rs-Redesigned.Specifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/redesigned-specifications.png') round;
  }
  
  .icon-rs-Grenadier.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/grenadier-training.png') round;
  }
  .icon-rs-Advanced.Scanning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/advanced-scanning.png') round;
  }
  
  .icon-rs-Spaceport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/spaceport.png') round;
  }
  
  .icon-rs-Cargo.Hovercraft {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/cargo-hovercraft.png') round;
  }
  .icon-rs-Hutt.Endorsement {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hutt-endorsement.png') round;
  }
  
  .icon-rs-Galactic.Banking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/galactic-banking.png') round;
  }
  
  .icon-rs-Galactic.Trade.Comm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/galactic-trade-commission.png') round;
  }
  .icon-rs-Holonet.Tranceiver {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/holonet-tranceiver.png') round;
  }
  
  
  .icon-rs-Cargo.Freighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/cargo-freighter.png') round;
  }
  
  .icon-rs-Research.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/research-center.png') round;
  }
  
  .icon-rs-Durasteel.Suppt.Beams {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/durasteel-support-beams.png') round;
  }
  
  .icon-rs-Permacite.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/permacite-plating.png') round;
  }
  
  .icon-rs-Ion.Accu.Accelerator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/ion-accu-accelerator.png') round;
  }
  
  .icon-rs-Rotation.Bearings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/rotation-bearings.png') round;
  }
  
  .icon-rs-Hvy.Weapons.Engineers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-weapons-engineers.png') round;
  }
  
  .icon-rs-Power.Calibrator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/power-calibrator.png') round;
  }
  
  .icon-rs-Tracking.and.Target.Comptr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/tracking-and-target-computers.png') round;
  }
    
  .icon-rs-AA.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/aa-battery.png') round;
  }
  
  .icon-rs-Homing.Sensors {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/homing-sensors.png') round;
  }
  
  .icon-rs-Carbon.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/carbon-proc-ctr.png') round;
  }
  
  .icon-rs-Handheld.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/handheld-carbon-extr.png') round;
  }
  
  .icon-rs-Enhanced.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/enhanced-carbon-extr.png') round;
  }
  
  .icon-rs-Hvy.Duty.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-duty-carbon-extr.png') round;
  }
  
  .icon-rs-Food.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/food-proc-ctr.png') round;
  }
  
  .icon-rs-Irrigation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/irrigation.png') round;
  }
  .icon-rs-Farm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/farm.png') round;
  }
  
  .icon-rs-Harvesting.Program {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/harvesting-program.png') round;
  }
  
  
  .icon-rs-Adv.Harvest.Prgm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-harvest-program.png') round;
  }
  
  .icon-rs-Animal.Nursery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/animal-nursery.png') round;
  }
  .icon-rs-Stimulants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/stimulants.png') round;
  }
  
  .icon-rs-Genetics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/genetics.png') round;
  }
  
  .icon-rs-Cloning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/cloning.png') round;
  }
  
  .icon-rs-Nova.Crystal.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/nova-proc-ctr.png') round;
  }
  .icon-nova-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/beamdrill-mining-nova.png') round;
  }
  
  .icon-nova-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-duty-beamdrill-nova.png') round;
  }
  
  .icon-rs-Ore.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/ore-proc-ctr.png') round;
  }
  
  .icon-ore-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/beamdrill-mining-ore.png') round;
  }
  
  .icon-ore-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-duty-beamdrill-ore.png') round;
  }
  .icon-rs-Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/prefab-shelter.png') round;
  }
  .icon-rs-Gate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/gate.png') round;
  }
  
  .icon-rs-Light.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/light-wall.png') round;
  }
  .icon-rs-Medium.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/medium-wall.png') round;
  }
  .icon-rs-Shield.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/shield-wall.png') round;
  }
  
  .icon-rs-Hvy.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-wall.png') round;
  }
  .icon-rs-Monument {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/monument.png') round;
  }
  .icon-rs-Shield.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/shield-generator.png') round;
  }
  
  .icon-rs-Strengthened.Superstructure {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/strengthened-superstructure.png') round;
  }
  .icon-rs-Supercondctg.Shields {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/superconducting-shields.png') round;
  }
  .icon-rs-Light.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/light-turret.png') round;
  }
  
  .icon-rs-Medium.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/medium-turret.png') round;
  }
  
  .icon-rs-Advanced.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/advanced-turret.png') round;
  }
  .icon-rs-Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/anti-air-turret.png') round;
  }
  
  .icon-rs-Adv.Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-anti-air-turret.png') round;
  }
  
  .icon-rs-Power.Core {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/power-core.png') round;
  }
  .icon-rs-Power.Droid {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/power-droid.png') round;
  }
  .icon-rs-Strengthened.Assembly {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/strengthened-assembly.png') round;
  }
  
  .icon-rs-Efficient.Buildings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/efficient-buildings.png') round;
  }
  
  .icon-rs-Power.Core.Shielding {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/power-core-shielding.png') round;
  }
  .icon-rs-Mechanics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/mechanics.png') round;
  }
  .icon-rs-Advanced.Propulsion {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/advanced-propulsion.png') round;
  }
  .icon-rs-Redoubled.Efforts {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/redoubled-efforts.png') round;
  }
  
  .icon-rs-Bunkerbusters {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/bunkerbusters.png') round;
  }
  
  .icon-rs-Sensor.Cloak{
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/sensor-cloak.png') round;
  }
  
  .icon-rs-Ski.Speeder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/ski-speeder.png') round;
  }
  
  .icon-rs-Hvy.Ski.Speeder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/hvy-ski-speeder.png') round;
  }
  
  .icon-rs-Resilience {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/resilience.png') round;
  }
  
  .icon-rs-Supply.lines {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/supply-lines.png') round;
  }
  
  .icon-rs-Adv.Redesign {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/adv-redesign.png') round;
  }
  .icon-rs-Technicians {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/technicians.png') round;
  }
  .icon-rs-Interceptor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/interceptor.png') round;
  }
  .icon-rs-Sentry.Post {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679538/rs/sentry-post.png') round;
  }

  .icon-rs-Mine {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/rs/mine.png') round;
  }
  .icon-rs-Anti-Air.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/rs/anti-air-battery.png') round;
  }