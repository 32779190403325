.icon-ra-Troop.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/troop-center.png') round;
  }
  .icon-ra-Trooper.Recruit {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/trooper_recruit.png') round;
  }
  .icon-ra-Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/trooper.png') round;
  }
  .icon-ra-Hvy\..Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy_trooper.png') round;
  }
  .icon-ra-Repeater.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/repeater-trooper.png') round;
  }
  .icon-ra-Grenade.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/grenade_trooper.png') round;
  }
  .icon-ra-Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/anti-air-trooper.png') round;
  }
  
  .icon-ra-Hvy\..Anti-Air.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-anti-air-trooper.png') round;
  }
  .icon-ra-Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/mounted-trooper.png') round;
  }
  .icon-ra-Hvy\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-mounted-trooper.png') round;
  }
  .icon-ra-Adv\..Mounted.Trooper {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1631279710/ra/adv-mounted-trooper.png') round;
  }
  
  .icon-ra-Macro.Binoculars {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/macro-binoculars.png') round;
  }
  
  .icon-ra-Lighter.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/lighter-armor.png') round;
  }
  .icon-ra-Plasma.Detonators {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/plasma-detonators.png') round;
  }
  .icon-ra-Dexterity {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/dexterity.png') round;
  }
  .icon-ra-Droid.Assistants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/droid-assistants.png') round;
  }
  
  .icon-ra-Explosive.Yields.Increase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/explosive-yields-increased.png') round;
  }
  
  .icon-ra-Portable.Scanners {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/portable-scanners.png') round;
  }
  
  .icon-ra-Integrated.Rangefinder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/integrated_rangefinder.png') round;
  }
  
  .icon-ra-Jedi.Temple {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/jedi-temple.png') round;
  }
  
  .icon-ra-Jedi.Padawan {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/jedi-padawan.png') round;
  }
  .icon-ra-Jedi.Knight {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/jedi-knight.png') round;
  }
  .icon-ra-Jedi.Master {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/jedi-master.png') round;
  }
  .icon-ra-Force.Stamina {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-stamina.png') round;
  }
  .icon-ra-Force.Strong {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-strong.png') round;
  }
  .icon-ra-Force.Agility {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-agility.png') round;
  }
  .icon-ra-Faith.in.the.Force {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/faith-in-the-force.png') round;
  }
  .icon-ra-Force.Concentration {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-concentration.png') round;
  }
  .icon-ra-Force.Meditation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-meditation.png') round;
  }
  .icon-ra-Force.Perception {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-perception.png') round;
  }
  
  .icon-ra-Jedi.Mind.Trick {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/jedi-mind-trick.png') round;
  }
  
  .icon-ra-Force.Purge {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-purge.png') round;
  }
  
  .icon-ra-Airbase {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/airbase.png') round;
  }
  .icon-ra-Air.Transport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/air-transport.png') round;
  }
  .icon-ra-Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/bomber.png') round;
  }
  .icon-ra-Enh.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/enh-bomber.png') round;
  }
  .icon-ra-Adv.Bomber {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-bomber.png') round;
  }
  .icon-ra-Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/fighter.png') round;
  }
  .icon-ra-Fast.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/fast-fighter.png') round;
  }
  .icon-ra-Adv.Fighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-fighter.png') round;
  }
  .icon-ra-Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/flight-school.png') round;
  }
  .icon-ra-Advanced.Flight.School {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/advanced-flight-school.png') round;
  }
  .icon-ra-Enlarged.Bomb.Hold {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/enlarged-bomb-hold.png') round;
  }
  .icon-ra-Attacker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/attacker.png') round;
  }
  .icon-ra-A-Wing {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/a-wing.png') round;
  }
  .icon-ra-Efficient.Manufacturing {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/efficient-manufacturing.png') round;
  }
  .icon-ra-Shield.Modifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/shield-modifications.png') round;
  }
  .icon-ra-Armored.Platework {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/armored-platework.png') round;
  }
  .icon-ra-Astromech.Repairs {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/astromech-repairs.png') round;
  }
  
  .icon-ra-Mech.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/mech-factory.png') round;
  }
  
  .icon-ra-Scout {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/scout.png') round;
  }
  
  .icon-ra-Strike.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/strike-mech.png') round;
  }
  .icon-ra-Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/mech-destroyer.png') round;
  }
  .icon-ra-Hvy.Mech.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-mech-destroyer.png') round;
  }
  .icon-ra-Upgraded.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/upgraded-generator.png') round;
  }
  .icon-ra-Transport.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/transport-mech.png') round;
  }
  .icon-ra-Assault.Mech {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/assault-mech.png') round;
  }
  
  .icon-ra-Shipyard {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/shipyard.png') round;
  }
  .icon-ra-Utility.Trawler {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/utility-trawler.png') round;
  }
  .icon-ra-Aqua.Harvester {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/aqua-harvester.png') round;
  }
  .icon-ra-Sensor.Buoy {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/sensor-buoy.png') round;
  }
  
  .icon-ra-Lt.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/lt-frigate.png') round;
  }
  .icon-ra-Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/frigate.png') round;
  }
  .icon-ra-Adv.Frigate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-frigate.png') round;
  }
  .icon-ra-Transport.Ship {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/transport-ship.png') round;
  }
  
  .icon-ra-Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/destroyer.png') round;
  }
  .icon-ra-Hvy.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-destroyer.png') round;
  }
  .icon-ra-Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/anti-air-destroyer.png') round;
  }
  .icon-ra-Hvy.Anti-Air.Destroyer {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-anti-air-destroyer.png') round;
  }
  
  .icon-ra-Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/monitor.png') round;
  }
  .icon-ra-Hvy.Monitor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-monitor.png') round;
  }
  .icon-ra-Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/cruiser.png') round;
  }
  .icon-ra-Adv.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-cruiser.png') round;
  }
  
  .icon-ra-Hvy.Weapons.Factory {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-weapons-factory.png') round;
  }
  
  .icon-ra-Pummel {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/pummel.png') round;
  }
  
  .icon-ra-Artillery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/artillery.png') round;
  }
  
  .icon-ra-Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/anti-air-mobile.png') round;
  }
  
  .icon-ra-Hvy.Anti-Air.Mobile {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-anti-air-mobile.png') round;
  }
  
  .icon-ra-Strengthened.Frame {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/strengthened-frame.png') round;
  }
  
  .icon-ra-Airspeeder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/airspeeder.png') round;
  }
    
  .icon-ra-Armored.Airspeeder {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/armored-airspeeder.png') round;
  }
  
  .icon-ra-Air.Cruiser {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/air-cruiser.png') round;
  }
  
  .icon-ra-Cannon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/cannon.png') round;
  }
  
  .icon-ra-Bothan.Spy.Net {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/bothan-spy-net.png') round;
  }
  
  .icon-ra-Attack.Programming {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/attack-programming.png') round;
  }
  
  .icon-ra-Automated.Processes {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/automated-processes.png') round;
  }
  .icon-ra-Presidium {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/presidium.png') round;
  }
  
  .icon-ra-Bounty.Hunter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/bounty-hunter.png') round;
  }
  
  .icon-ra-Fortress {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/fortress.png') round;
  }
  
  .icon-ra-Worker {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/worker.png') round;
  }
  
  .icon-ra-Medic {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/medic.png') round;
  }
  
  .icon-ra-Basic.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/basic-training.png') round;
  }
  
  .icon-ra-Sensor.Beacon {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/sensor-beacon.png') round;
  }
  
  .icon-ra-Sensor.Array {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/sensor-array.png') round;
  }
  
  .icon-ra-Upgraded.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/upgraded-motivator.png') round;
  }
  
  .icon-ra-Optimized.Motivator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/optimized-motivator.png') round;
  }
  
  .icon-ra-Bacta.Tanks {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/bacta-tanks.png') round;
  }
  
  .icon-ra-Command.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/command-center.png') round;
  }
  
  .icon-ra-War.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/war-center.png') round;
  }
  .icon-ra-Basic.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/basic-armor.png') round;
  }
  .icon-ra-Light.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/light-armor.png') round;
  }
  .icon-ra-Hvy.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-armor.png') round;
  }
  .icon-ra-Elevation.Tracking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/elevation-tracking.png') round;
  }
  .icon-ra-External.Sensor.Pod {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/external-sensor-pod.png') round;
  }
  .icon-ra-Targeting.Sensor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/targeting-sensor.png') round;
  }
  .icon-ra-Light.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/light-plating.png') round;
  }
  .icon-ra-Medium.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/medium-plating.png') round;
  }
  .icon-ra-Hvy.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-plating.png') round;
  }
  .icon-ra-Primary.Focusing.Coils {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/primary-focusing-coils.png') round;
  }
  .icon-ra-Cooling.Sleeves {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/cooling-sleeves.png') round;
  }
  
  .icon-ra-Advanced.Power.Pack {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/advanced-power-pack.png') round;
  }
  .icon-ra-Redesigned.Specifications {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/redesigned-specifications.png') round;
  }
  
  .icon-ra-Grenadier.Training {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/grenadier-training.png') round;
  }
  .icon-ra-Advanced.Scanning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/advanced-scanning.png') round;
  }
  
  .icon-ra-Tougher.Armor {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/tougher-armor.png') round;
  }
  .icon-ra-Spaceport {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/spaceport.png') round;
  }
  
  .icon-ra-Cargo.Hovercraft {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/cargo-hovercraft.png') round;
  }
  .icon-ra-Hutt.Endorsement {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hutt-endorsement.png') round;
  }
  
  .icon-ra-Galactic.Banking {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/galactic-banking.png') round;
  }
  
  .icon-ra-Galactic.Trade.Comm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/galactic-trade-commission.png') round;
  }
  .icon-ra-Holonet.Tranceiver {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/holonet-tranceiver.png') round;
  }
  
  
  .icon-ra-Cargo.Freighter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/cargo-freighter.png') round;
  }
  
  .icon-ra-Research.Center {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/research-center.png') round;
  }
  
  .icon-ra-Durasteel.Suppt.Beams {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/durasteel-support-beams.png') round;
  }
  
  .icon-ra-Permacite.Plating {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/permacite-plating.png') round;
  }
  
  .icon-ra-Ion.Accu.Accelerator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/ion-accu-accelerator.png') round;
  }
  
  .icon-ra-Rotation.Bearings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/rotation-bearings.png') round;
  }
  
  .icon-ra-Hvy.Weapons.Engineers {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-weapons-engineers.png') round;
  }
  
  .icon-ra-Power.Calibrator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/power-calibrator.png') round;
  }
  
  .icon-ra-Tracking.and.Target.Comptr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/tracking-and-target-computers.png') round;
  }
  
  .icon-ra-Evacuation.Protocols {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/evacuation-protocols.png') round;
  }
  
  .icon-ra-AA.Battery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/aa-battery.png') round;
  }
  
  .icon-ra-Homing.Sensors {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/homing-sensors.png') round;
  }
  
  .icon-ra-Carbon.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/carbon-proc-ctr.png') round;
  }
  
  .icon-ra-Handheld.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/handheld-carbon-extr.png') round;
  }
  
  .icon-ra-Enhanced.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/enhanced-carbon-extr.png') round;
  }
  
  .icon-ra-Hvy.Duty.Carbon.Extr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-duty-carbon-extr.png') round;
  }
  
  .icon-ra-Food.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/food-proc-ctr.png') round;
  }
  
  .icon-ra-Irrigation {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/irrigation.png') round;
  }
  .icon-ra-Farm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/farm.png') round;
  }
  
  .icon-ra-Harvesting.Program {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/harvesting-program.png') round;
  }
  
  
  .icon-ra-Adv.Harvest.Prgm {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-harvest-program.png') round;
  }
  
  .icon-ra-Animal.Nursery {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/animal-nursery.png') round;
  }
  .icon-ra-Stimulants {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/stimulants.png') round;
  }
  
  .icon-ra-Genetics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/genetics.png') round;
  }
  
  .icon-ra-Cloning {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/cloning.png') round;
  }
  
  .icon-ra-Nova.Crystal.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/nova-proc-ctr.png') round;
  }
  .icon-nova-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/beamdrill-mining-nova.png') round;
  }
  
  .icon-nova-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-duty-beamdrill-nova.png') round;
  }
  
  .icon-ra-Ore.Proc.Ctr {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/ore-proc-ctr.png') round;
  }
  
  .icon-ore-Beamdrill.Mining {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/beamdrill-mining-ore.png') round;
  }
  
  .icon-ore-Hvy.Duty.Beamdrill {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-duty-beamdrill-ore.png') round;
  }
  .icon-ra-Prefab.Shelter {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/prefab-shelter.png') round;
  }
  .icon-ra-Gate {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/gate.png') round;
  }
  
  .icon-ra-Light.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/light-wall.png') round;
  }
  .icon-ra-Medium.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/medium-wall.png') round;
  }
  .icon-ra-Shield.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/shield-wall.png') round;
  }
  
  .icon-ra-Hvy.Wall {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-wall.png') round;
  }
  .icon-ra-Monument {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/monument.png') round;
  }
  .icon-ra-Shield.Generator {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/shield-generator.png') round;
  }
  
  .icon-ra-Strengthened.Superstructure {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/strengthened-superstructure.png') round;
  }
  .icon-ra-Supercondctg.Shields {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/superconducting-shields.png') round;
  }
  .icon-ra-Light.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/light-turret.png') round;
  }
  
  .icon-ra-Medium.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/medium-turret.png') round;
  }
  
  .icon-ra-Advanced.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/advanced-turret.png') round;
  }
  .icon-ra-Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/anti-air-turret.png') round;
  }
  
  .icon-ra-Adv.Anti-Air.Turret {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-anti-air-turret.png') round;
  }
  
  .icon-ra-Power.Core {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/power-core.png') round;
  }
  .icon-ra-Power.Droid {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/power-droid.png') round;
  }
  .icon-ra-Strengthened.Assembly {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/strengthened-assembly.png') round;
  }
  
  .icon-ra-Efficient.Buildings {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/efficient-buildings.png') round;
  }
  
  .icon-ra-Power.Core.Shielding {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/power-core-shielding.png') round;
  }
  .icon-ra-Mechanics {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/mechanics.png') round;
  }
  .icon-ra-Advanced.Propulsion {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/advanced-propulsion.png') round;
  }
  .icon-ra-Redoubled.Efforts {
    background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/redoubled-efforts.png') round;
  }

.icon-ra-Adv.Generator {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round,url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-generator.png') round;
}
.icon-ra-Adv.Redesign {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/adv-redesign.png') round;
}

.icon-ra-Hvy.Strike.Mech {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-strike-mech.png') round;
}
.icon-ra-Hvy.Artillery {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-artillery.png') round;
}
.icon-ra-Hvy.Pummel {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-pummel.png') round;
}
.icon-ra-Reinforced.Frame {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/reinforced-frame.png') round;
}
.icon-ra-Anti-Air.Retrofit {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/anti-air-retrofit.png') round;
}
.icon-ra-Sentry.Post {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/sentry-post.png') round;
}



.icon-ra-Hvy.Assault.Mech {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/hvy-assault-mech.png') round;
}

.icon-ra-Technicians {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/technicians.png') round;
}
.icon-ra-Force.Influence {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630928553/red_x.png') round, url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/force-influence.png') round;
}
.icon-ra-Interceptor {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630679641/ra/interceptor.png') round;
}

.icon-ra-Mine {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/ra/mine.png') round;
}
.icon-ra-Anti-Air.Battery {
  background: url('https://res.cloudinary.com/swbgtechtree/image/upload/v1630678561/ra/anti-air-battery.png') round;
}